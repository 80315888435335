import './styles/index.css';
import React, { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import fetchUser from '@misto/common/actions/fetchUser';
import fetchCart from '@misto/common/actions/fetchCart';
import clearFilters from '@misto/common/actions/clearFilters';
import MainLayout from './layout/MainLayout';
import PrivateOutlet from '@misto/common/outlets/PrivateOutlet';
import RedirectOnAuthOutlet from '@misto/common/outlets/RedirectOnAuthOutlet';
import { fetchSettings } from './actions';
import constants from '@misto/common/constants';
import useTitle from '@misto/common/hooks/useTitle';
import CookiesPrompt from './layout/CookiesPrompt';

// const Post = lazy(() => import('./pages/Post'));
const Clothes = lazy(() => import('./pages/Clothes'));
const Home = lazy(() => import('./pages/Home'));
const Product = lazy(() => import('./pages/Product'));
// const Cart = lazy(() => import('./pages/Cart'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Profile = lazy(() => import('./pages/Profile'));
const MyOrders = lazy(() => import('./pages/MyOrders'));
const Completion = lazy(() => import('./pages/Completion'));
// const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Order = lazy(() => import('./pages/Order'));
// const Blog = lazy(() => import('./pages/Blog'));
const SiteInfo = lazy(() => import('./pages/SiteInfo'));
const Verification = lazy(() => import('@misto/common/pages/Verification'));
const ResetPassword = lazy(() => import('@misto/common/pages/ResetPassword'));
const Returns = lazy(() => import('./pages/Returns'));
const NewReturn = lazy(() => import('./pages/NewReturn'));
const Return = lazy(() => import('./pages/Return'));
const VerifySubscription = lazy(() => import('./pages/VerifySubscription'));

function App({ fetchCart, fetchUser, fetchSettings }) {
  useTitle(constants.websiteName);

  useEffect(() => {
    fetchUser();
    fetchCart();
    fetchSettings();
  }, [fetchUser, fetchSettings, fetchCart]);

  return (
    <div className="font-[Arvo]">
      <Routes>
        <Route element={<CookiesPrompt />}>
          <Route element={<MainLayout />}>
            <Route path="checkout" element={<Checkout />} />
            <Route index element={<Home />} />
            <Route
              path="/verify-subscription"
              element={<VerifySubscription />}
            />
            <Route path="/site-info/:name" element={<SiteInfo />} />
            <Route path="clothes" element={<Clothes />} />
            <Route path="clothes/:category" element={<Clothes />} />
            <Route path="products/:id" element={<Product />} />
            <Route path="profile" element={<Profile />} />
            <Route path="completion" element={<Completion />} />
            {/* <Route path="posts/:id" element={<Post />} /> */}
            {/* <Route path="blog" element={<Blog />} /> */}
          </Route>
          <Route element={<MainLayout />}>
            <Route element={<PrivateOutlet />}>
              {/* <Route path="cart" element={<Cart />} /> */}
              <Route path="/returns/:id" element={<Return />} />
              <Route path="/new-return" element={<NewReturn />} />
              <Route path="/my-returns" element={<Returns />} />
              <Route path="checkout" element={<Checkout />} />
              <Route path="my-orders" element={<MyOrders />} />
              <Route path="orders/:id" element={<Order />} />
            </Route>
          </Route>
          <Route element={<RedirectOnAuthOutlet />}>
            <Route path="register" element={<Register />} />
            {/*<Route path="login" element={<Login />} />*/}
            <Route path="verify-account" element={<Verification />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default connect(null, {
  clearFilters,
  fetchUser,
  fetchSettings,
  fetchCart,
})(App);
