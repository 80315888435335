import React, { useCallback, useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import constants from '@misto/common/constants';
import maximizeTawkContext from '@misto/common/context/maximizeTawkContext';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

export default function MainLayout() {
  const location = useLocation();
  const tawkMessengerRef = useRef();

  const maximizeTawk = useCallback(() => {
    if (tawkMessengerRef.current) {
      tawkMessengerRef.current.maximize();
    }
  }, [tawkMessengerRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <maximizeTawkContext.Provider value={maximizeTawk}>
      <div className="relative flex flex-col min-h-screen">
        <NavBar />
        <div className="mt-[151px] xl:mt-[108px] flex-1 sm:flex-none">
          <TawkMessengerReact
            propertyId={constants.tawkPropertyId}
            widgetId={constants.tawkWidgetId}
            ref={tawkMessengerRef}
          />
          <Outlet />
        </div>
        <div className="sm:flex-1 flex items-end">
          <Footer />
        </div>
      </div>
    </maximizeTawkContext.Provider>
  );
}
