import React from 'react';

export default function Throbber({ className, isHidden }) {
  return (
    <div
      hidden={isHidden}
      className={`${className} rounded-full border-t-transparent animate-spin`}
    />
  );
}
