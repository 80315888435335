import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function InfoBar() {
  const [broadcast, setBroadcast] = useState(null);

  useEffect(() => {
    const fetchBroadcast = async () => {
      try {
        const { data } = await axios.get(
          '/api/broadcasts?name=top_bar_broadcast'
        );

        if (data?.broadcasts?.[0]) setBroadcast(data.broadcasts[0]);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    };

    fetchBroadcast();
  }, []);

  if (!broadcast) return null;

  const renderText = () => (
    <div className="absolute animate-slide-x whitespace-nowrap w-fit flex text-sm items-center justify-center gap-8 inset-y-0 my-auto">
      {broadcast.title ? <p>{broadcast.title}</p> : null}
      {broadcast.title && broadcast.content ? (
        <span className="hidden md:block">-</span>
      ) : null}
      {broadcast.content ? (
        <p className={broadcast.title ? 'hidden md:block' : ''}>
          {broadcast.content}
        </p>
      ) : null}
    </div>
  );

  return (
    <div className="bg-black w-screen h-7 z-10 font-sans relative text-white">
      {renderText()}
    </div>
  );
}
