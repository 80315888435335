import axios from 'axios';
import { GET_AUTH } from './types.js';

const fetchUser = () => async (dispatch) => {
  const res = await axios.get('/api/current_user');

  dispatch({
    type: GET_AUTH,
    payload: res.data || false,
  });
};

export default fetchUser;
