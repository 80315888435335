/* eslint-disable no-shadow */
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import usePathname from '../hooks/usePathname';
import Cart from './Cart';
import SideBar from './SideBar';
import Favorites from './Favorites';
import showLogin from '../actions/showLogin.js';
import hideLogin from '../actions/hideLogin.js';
import Login from './Login.jsx';

function CartProfileLinks({
  className,
  cart,
  auth,
  favorites,
  isLoginVisible,
  showLogin,
  hideLogin,
}) {
  const pathname = usePathname();
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [isFavoritesVisible, setIsFavoritesVisible] = useState(false);
  const cartButtonRef = useRef();
  const textHoverEffects =
    'text-gray-500 sm:text-black md:hover:opacity-70 md:transition-all';
  const profilePath = '/profile';

  const renderProfile = () => {
    if (auth)
      return (
        <Link
          to="/profile"
          className={pathname === profilePath ? 'text-black' : textHoverEffects}
        >
          <i className="fa-solid fa-user"></i>
        </Link>
      );

    return (
      <div className="relative">
        <button
          onClick={() => {
            if (isLoginVisible) hideLogin();
            else showLogin();
          }}
          type="button"
          className={pathname === profilePath ? 'text-black' : textHoverEffects}
        >
          <i className="fa-solid fa-user"></i>
        </button>
        <Login
          className={`p-4 rounded-lg bg-white shadow-lg absolute -right-9 flex flex-col gap-3 text-sm min-w-[300px] ${
            isLoginVisible ? 'animate-fade-in' : 'animate-fade-out'
          }`}
        />
      </div>
    );
  };

  return (
    <div className={`relative ${className}`}>
      {renderProfile()}
      <button
        className={`relative ${textHoverEffects}`}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setIsFavoritesVisible((val) => !val);
        }}
      >
        {favorites.length !== 0 ? (
          <p className="absolute w-5 h-5 flex items-center justify-center -top-2.5 -right-2.5 bg-red-600 text-white rounded-full text-xs">
            {favorites.length}
          </p>
        ) : null}
        <i className="fa-solid fa-heart"></i>
      </button>
      <button
        ref={cartButtonRef}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setIsCartVisible((prev) => !prev);
        }}
        className={`relative transition-all ${
          isCartVisible ? 'text-black' : textHoverEffects
        }`}
      >
        {cart.length !== 0 ? (
          <p className="absolute w-5 h-5 flex items-center justify-center -top-2.5 -right-2.5 bg-red-600 text-white rounded-full text-xs">
            {cart.length}
          </p>
        ) : null}
        <i className="fa-solid fa-cart-shopping"></i>
      </button>
      {/* <Cart
        className="w-[90vw] max-w-sm max-h-96 flex flex-col absolute top-full right-0 animate-fade-in bg-white shadow-lg p-5 rounded-lg"
        isVisible={isCartVisible && isCartButtonVisible}
        setIsVisible={setIsCartVisible}
      /> */}
      <SideBar
        key="cart"
        isHidden={!isCartVisible}
        setIsHidden={(val) => setIsCartVisible(!val)}
      >
        <div className="bg-white inset-0 p-5 absolute overflow-hidden min-[500px]:inset-5 min-[500px]:rounded-xl shadow-lg">
          <Cart className="h-full pb-7" setIsCartVisible={setIsCartVisible} />
        </div>
      </SideBar>
      <SideBar
        key="favorites"
        isHidden={!isFavoritesVisible}
        setIsHidden={(val) => setIsFavoritesVisible(!val)}
      >
        <div className="bg-white p-5 inset-0 absolute overflow-hidden min-[500px]:inset-5 min-[500px]:rounded-xl shadow-lg">
          <Favorites
            className="h-full pb-7"
            setIsFavoritesVisible={setIsFavoritesVisible}
          />
        </div>
      </SideBar>
    </div>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart || [],
  favorites: state.auth?.favorites || [],
  auth: state.auth,
  isLoginVisible: state.isLoginVisible,
});

export default connect(mapStateToProps, { showLogin, hideLogin })(
  CartProfileLinks
);
