/* eslint-disable no-shadow */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import setFilters from '../actions/setFilters';
import Logo from './Logo';
import SearchBar from './SearchBar';
import capitalizeFirstLetter from '../helpers/capitalizeFirstLetter';
import CartProfileLinks from './CartProfileLinks';
import maximizeTawkContext from '../context/maximizeTawkContext';

// eslint-disable-next-line no-shadow
function HomeHeaderContent({
  filters,
  setFilters,
  className,
  bottomBreadClassName,
}) {
  const textHoverEffects =
    'whitespace-nowrap text-center hover:after:border hover:after:border-black hover:after:absolute hover:after:-inset-1 hover:hover:after:rounded-full relative hover:bg-[#95b2b5] md:hover:text-white px-2 md:transition-all rounded-full';

  const [categories, setCategories] = useState([]);
  const [term, setTerm] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const maximizeTawk = useContext(maximizeTawkContext);

  useEffect(() => {
    setTerm(filters.title);
  }, [filters.title]);

  useEffect(() => {
    if (location.pathname.split('/')[1] !== 'clothes') {
      setFilters({ title: '' });
    }
  }, [location.pathname]);

  const onSearch = () => {
    setFilters({ title: term, page: 0 });
    navigate('/clothes');
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const { data } = await axios.get('/api/categories?showOnNavbar=true');

      if (data.categories) setCategories(data.categories);
    };

    fetchCategories();
  }, []);

  const renderNavLinks = () => (
    <>
      <Link className={textHoverEffects} to="/">
        Home
      </Link>
      {categories.map((c) => (
        <Link className={textHoverEffects} to={`/clothes/${c._id}`} key={c._id}>
          {capitalizeFirstLetter(c.name)}
        </Link>
      ))}
      <Link className={textHoverEffects} to="/clothes">
        Shop Now
      </Link>
      <Link className={textHoverEffects} to="/site-info/about-us">
        About Us
      </Link>
      <Link className={textHoverEffects} to="/site-info/faqs">
        FAQs
      </Link>
      <button type="button" className={textHoverEffects} onClick={maximizeTawk}>
        Contact Us
      </button>
    </>
  );

  return (
    <div>
      <div className={className}>
        <section>
          <Logo className="hidden sm:block text-2xl font-sans font-bold leading-6 lowercase text-[#c3866b] max-w-[160px] hover:opacity-70 transition-all" />
        </section>
        <section className="hidden gap-5 items-center xl:flex">
          {renderNavLinks()}
        </section>
        <section className="flex items-center gap-3 flex-1 xl:flex-none px-3 md:px-0">
          <SearchBar
            term={term}
            setTerm={setTerm}
            onSearch={onSearch}
            placeholder="Search"
            containerClassName="flex w-full items-center rounded-full gap-3 bg-white border-black border px-4 py-1"
            className="w-full bg-transparent placeholder:text-gray-700"
          />
          <CartProfileLinks className="flex w-fit items-center gap-2 sm:gap-4 z-10 relative" />
        </section>
      </div>
      <div
        className={`xl:hidden py-2 px-4 relative w-full overflow-x-auto ${bottomBreadClassName}`}
      >
        <div className="w-fit flex m-auto gap-5">{renderNavLinks()}</div>
      </div>
    </div>
  );
}

export default connect(({ filters }) => ({ filters }), { setFilters })(
  HomeHeaderContent
);
