import React from 'react';
import afterpay from './images/afterpay.png';
import zipPay from './images/zip-pay.png';

export default function PoweredBy({ className }) {
  return (
    <div
      className={className || 'flex-1 flex justify-center items-center gap-3'}
    >
      <i className="fa-lg fa-brands fa-stripe"></i>
      <i className="fa-lg fa-brands fa-cc-visa"></i>
      <i className="fa-lg fa-brands fa-cc-mastercard"></i>
      <img className="w-28 h-7 object-cover" src={afterpay} alt="afterpay" />
      <img className="w-20 h-7 object-contain" src={zipPay} alt="zip pay" />
    </div>
  );
}
