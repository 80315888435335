import React, { useState, useRef } from 'react';

export default function SearchBar({
  onSearch,
  placeholder,
  className,
  initialHiding,
  containerClassName,
  term,
  setTerm,
}) {
  const [isHidden, setIsHidden] = useState(initialHiding);
  const searchInputRef = useRef();

  const onSubmit = (e) => {
    e.preventDefault();
    onSearch(term);
  };

  if (isHidden) {
    return (
      <div className="flex-1 flex justify-end">
        <button
          onClick={() => setIsHidden(false)}
          type="button"
          className="text-lg"
        >
          <i className="fa-solid fa-magnifying-glass" />
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} className={containerClassName}>
      <input
        ref={searchInputRef}
        placeholder={placeholder || ''}
        value={term}
        onChange={(e) => setTerm(e.target.value)}
        className={className}
      />
      <button className="text-lg" type="submit">
        <i className="fa-solid fa-magnifying-glass"></i>
      </button>
    </form>
  );
}
