import axios from 'axios';
import { FETCH_CART } from './types.js';

const fetchCart = () => async (dispatch) => {
  const { data } = await axios.get('/api/items');

  dispatch({
    type: FETCH_CART,
    payload: data?.items || [],
  });
};

export default fetchCart;
