import React, { Suspense } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import App from './App';
import reducers from './reducers';
import constants from '@misto/common/constants';
import Throbber from '@misto/common/components/Throbber';
import { BrowserRouter } from 'react-router-dom';

axios.defaults.baseURL = constants.apiUrl;
axios.defaults.withCredentials = true;

const enhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, enhancers(applyMiddleware(thunk)));
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Suspense
      fallback={
        <div className="w-screen h-screen flex items-center justify-center">
          <Throbber className="w-12 h-12 border-8 border-black" />
        </div>
      }
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>
);
