const constants = {
  apiUrl:
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'staging'
      ? window.location.origin
      : `http://localhost:5006`,
  stripeKey:
    process.env.REACT_APP_ENV === 'production'
      ? 'pk_live_51NHP00BXaEgA8nNzzZd9RUcZXJ3lRA8auMb1c7ST96OyjclJ14zpqXjoZiTTPwebf4kPDLNSgraKrwjaPhd0XJpZ004xDevky4'
      : 'pk_test_51Lite1IkjpXn1y08PBWbA5W1KN9C7OhueMl6I0iKkUM85WU8TcFeePxzAe4caYBTd0fqiYWUdayGmcW9FwhV5T1w00ZJ6bP2sf',
  websiteName: 'Welcome Nest',
  tawkPropertyId:
    process.env.REACT_APP_ENV === 'production'
      ? '65a77adc0ff6374032c13094'
      : '648d7294cc26a871b023190f',
  tawkWidgetId:
    process.env.REACT_APP_ENV === 'production' ? '1hkb3frgt' : '1h348f61a',
};

export default constants;
