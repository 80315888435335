import React, { useContext } from 'react';
import currentMenuContext from './currentMenuContext';

export default function Menu({ menuNumber, children }) {
  const currentMenu = useContext(currentMenuContext);

  if (menuNumber !== currentMenu) return null;

  return <>{children}</>;
}
