import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '@misto/common/components/Modal';
import { connect } from 'react-redux';
import axios from 'axios';
import setAuth from '@misto/common/actions/setAuth';
import Throbber from '@misto/common/components/Throbber';

function CookieAccess({ auth, setAuth }) {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async () => {
    try {
      if (auth && !auth.hasApprovedPrivacyPolicy) {
        setIsLoading(true);
        const data = await axios.put('/api/users/has-approved-privacy-policy', {
          hasApprovedPrivacyPolicy: true,
        });

        if (data._id) {
          setAuth(data);
          setIsOpen(false);
        }
      } else {
        setIsOpen(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (auth === null || auth?.hasApprovedPrivacyPolicy) return null;

  return (
    <Modal isOpen={isOpen} setIsOpen={(_) => null}>
      <div className="font-[Arvo] z-[999999] bg-[#d8cfc6] w-full p-6 animate-slide-up absolute bottom-0 inset-x-0 mx-auto flex flex-col gap-5">
        <p className="max-w-screen-lg m-auto">
          The use of cookies assists us to personalize your experience, deliver
          tailored messages and advertisements. By using our website, you agree
          to our use of cookies as governed by our{' '}
          <Link
            onClick={() => setIsOpen(false)}
            className="font-semibold hover:underline"
            to="/site-info/privacy-policy"
          >
            privacy policy
          </Link>
        </p>
        <div className="flex max-w-[200px] gap-3 m-auto">
          <button
            className="flex-1 flex items-center gap-3 bg-black text-white disabled:text-gray-500 disabled:bg-gray-100 px-3 py-1 rounded group"
            onClick={onConfirm}
            disabled={isLoading}
          >
            <Throbber className="w-5 aspect-square border-2 border-gray-500 group-disabled:block hidden" />{' '}
            Confirm
          </button>
          <button
            disabled={isLoading}
            className="flex-1 bg-white text-black px-3 py-1 rounded"
            onClick={() => setIsOpen(false)}
          >
            Deny
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default connect(({ auth }) => ({ auth }), { setAuth })(CookieAccess);
