import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Throbber from './Throbber';
import placeholder from './images/placeholder.jpg';
import constants from '../constants';
import Paginator from './Paginator';
import StartSoppingButton from './StartShoppingButton';
import FavoriteNotAvailable from './FavoriteNotAvailable';
import showLogin from '../actions/showLogin.js';

// eslint-disable-next-line no-shadow
function Favorites({ auth, setIsFavoritesVisible, showLogin }) {
  const [isLoading, setIsLoading] = useState(true);
  const [favorites, setFavorites] = useState([]);
  const [page, setPage] = useState(0);
  const pageSize = 12;

  useEffect(() => {
    const controller = new AbortController();

    const fetchFavorites = async () => {
      try {
        setIsLoading(true);

        const { data } = await axios.get(
          `/api/users/favorites?page=${page}&pageSize=${pageSize}`,
          { signal: controller.signal }
        );

        if (data.favorites) setFavorites(data.favorites);
      } finally {
        setIsLoading(false);
      }
    };

    if (auth) fetchFavorites();

    return () => {
      controller.abort();
    };
  }, [page, pageSize, auth]);

  if (auth === false) {
    return (
      <div className="flex flex-col gap-3">
        <header className="flex justify-between">
          <h3 className="text-2xl font-bold">Favorites</h3>
          <button type="button" onClick={() => setIsFavoritesVisible(false)}>
            <i className="fa-solid fa-xmark text-xl" />
          </button>
        </header>
        <p className="text-gray-400">
          You are not logged in yet, please login to view your favorites
        </p>
        <button
          type="button"
          onClick={() => {
            showLogin();
            setIsFavoritesVisible(false);
          }}
          className="font-semibold text-white bg-[#95b2b8] px-5 py-0.5 w-fit self-end rounded-full relative before:absolute before:rounded-full before:-inset-1 hover:before:border hover:before:border-black"
        >
          Login
        </button>
      </div>
    );
  }

  if (isLoading)
    return (
      <div className="w-full h-full py-10 flex items-center justify-center">
        <Throbber className="aspect-square w-16 border-8 border-black" />
      </div>
    );

  if (favorites.length === 0)
    return (
      <div className="text-lg py-10 flex flex-col gap-5 items-center">
        <p>You haven&apos;t added any products to your favorites</p>
        <StartSoppingButton
          to="/clothes"
          text="Start Shopping!"
          className="!text-lg"
        />
      </div>
    );

  const renderFavorites = () =>
    favorites.map((f) => {
      if (!f?.product) {
        return <FavoriteNotAvailable favorite={f} />;
      }

      const image = f.product.images.find((i) => i.color === f.color);

      const imageSrc = image
        ? `${constants.apiUrl}/api/products/${f.product._id}/image/${image.color}/${image.name}`
        : placeholder;

      return (
        <Link
          onClick={() => setIsFavoritesVisible(false)}
          key={f._id}
          className="w-full max-w-md flex gap-5 hover:opacity-50 transition-all items-center p-3 rounded border-b"
          to={`/products/${f.product._id}?defaultSize=${f.size}&defaultColor=${f.color}`}
        >
          <img
            className="bg-gray-100 w-full aspect-[4/5] max-w-[100px] rounded h-full object-contain"
            src={imageSrc}
            alt={f.product.title}
          />
          <section className="col-span-2 text-xs text-gray-400">
            <h3
              className="text-sm font-semibold line-clamp-1 text-black"
              title={f.product.title}
            >
              {f.product.title}
            </h3>
            <p>Color: {f.color}</p>
            <p>Size: {f.size}</p>
          </section>
        </Link>
      );
    });

  return (
    <div className="h-full">
      <header className="flex items-center pb-5 justify-between">
        <h1 className="font-semibold text-2xl">Favorites</h1>
        <button type="button" onClick={() => setIsFavoritesVisible(false)}>
          <i className="fa-solid fa-xmark text-xl" />
        </button>
      </header>
      <div className="flex flex-1 flex-col pb-10 items-center gap-5 overflow-auto h-full">
        {renderFavorites()}
        <Paginator
          page={page + 1}
          setPage={setPage}
          numberOfPages={Math.ceil(auth.favorites.length / pageSize)}
        />
      </div>
    </div>
  );
}

export default connect(({ auth }) => ({ auth }), { showLogin })(Favorites);
