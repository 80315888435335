import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HomeHeaderContent from './HomeHeaderContent';

export default function HomeHeader() {
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  const onScroll = useCallback(() => {
    if (window.scrollY > 50 && !visible) {
      setVisible(true);
    } else if (window.scrollY < 50 && visible) {
      setVisible(false);
    }
  }, [visible]);

  useEffect(() => {
    onScroll();
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname !== '/') {
      setVisible(true);
    }

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll, location.pathname]);

  return (
    <div
      className={`bg-white bg-opacity-80 backdrop-blur-sm w-full shadow absolute transition-all duration-500 ${
        !visible ? 'bg-transparent backdrop-blur-none shadow-none' : ''
      }`}
    >
      <HomeHeaderContent
        className="w-full max-w-screen-md xl:max-w-none xl:w-fit m-auto p-3 flex sm:gap-5 items-center justify-between"
        bottomBreadClassName={`bg-gray-50 bg-opacity-70 py-3 ${
          visible ? 'animate-fade-in' : 'animate-fade-out'
        }`}
      />
    </div>
  );
}
