import axios from 'axios';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import placeholder from './images/placeholder.jpg';
import { fetchUser } from '../actions';
import Throbber from './Throbber';

// eslint-disable-next-line no-shadow
function FavoriteNotAvailable({ favorite, fetchUser }) {
  const [isLoading, setIsLoading] = useState(false);

  const removeFavorite = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.delete(`/api/favorites/${favorite._id}`);

      if (data._id) fetchUser();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      key={favorite._id}
      className="w-full max-w-md flex gap-5 transition-all items-center shadow p-3 rounded"
    >
      <img
        src={placeholder}
        alt="unavailable"
        className="bg-gray-100 w-full max-w-[100px] rounded h-full object-contain"
      />
      <p className="col-span-2">Product in unavailable</p>
      <button
        type="button"
        disabled={isLoading}
        onClick={removeFavorite}
        className="group flex items-center justify-center ml-auto w-8 h-8 text-red-500 disabled:bg-red-100 hover:bg-red-100 rounded-full"
      >
        <i className="fa-solid group-disabled:hidden fa-trash-can" />
        <Throbber className="w-4 h-4 border-2 border-red-500 hidden group-disabled:block" />
      </button>
    </div>
  );
}

export default connect(null, { fetchUser })(FavoriteNotAvailable);
