import React from 'react';
import { connect } from 'react-redux';

function SocialLinks({ className, settings, linksClassName }) {
  const onHoverEffects = 'hover:text-gray-50 transition-all';

  return (
    <div className={`flex gap-2 ${className}`}>
      {settings?.socialAccounts?.facebook ? (
        <a
          href={settings?.socialAccounts?.facebook}
          target="blank"
          className={linksClassName || onHoverEffects}
          title="Join us on facebook!"
        >
          <i className="fa-brands fa-facebook"></i>
        </a>
      ) : null}
      {settings?.socialAccounts?.twitter ? (
        <a
          href={settings.socialAccounts.twitter}
          target="blank"
          className={linksClassName || onHoverEffects}
          title="Join us on twitter"
        >
          <i className="fa-brands fa-x-twitter"></i>
        </a>
      ) : null}
      {settings?.socialAccounts?.instagram ? (
        <a
          href={settings.socialAccounts.instagram}
          target="blank"
          className={linksClassName || onHoverEffects}
          title="Join us on instagram"
        >
          <i className="fa-brands fa-instagram"></i>
        </a>
      ) : null}
      {settings?.socialAccounts?.pinterest ? (
        <a
          href={settings.socialAccounts.pinterest}
          target="blank"
          className={linksClassName || onHoverEffects}
          title="Join us on pinterest"
        >
          <i className="fa-brands fa-pinterest"></i>
        </a>
      ) : null}
    </div>
  );
}

export default connect(({ settings }) => ({ settings }))(SocialLinks);
