/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

export default function SideBar({ children, isHidden, setIsHidden }) {
  useEffect(() => {
    document.addEventListener('click', () => {
      setIsHidden(true);
    });
  }, [setIsHidden]);

  return ReactDOM.createPortal(
    <div
      onClick={(e) => e.stopPropagation()}
      className={`z-50 w-screen max-w-md h-screen fixed top-0 right-0 ${
        isHidden ? 'translate-x-full' : 'translate-x-0'
      } transition-all duration-500`}
    >
      {children}
    </div>,
    document.querySelector('#side-bar')
  );
}
