import React from 'react';
import { Link } from 'react-router-dom';

export default function StartSoppingButton({ to, text, className }) {
  return (
    <Link
      to={to}
      className={`${className} relative block font-semibold text-white m-auto w-fit text-2xl mt-12 md:mt-12 md:-top-10 py-1 px-5 rounded-full before:w-full before:h-full before:absolute before:top-0 before:left-0 before:rounded-full before:shadow-[0_0_0_2px_black] after:w-full after:bg-[#95b2b8] after:h-full after:absolute after:block after:rounded-full after:top-1.5 after:-left-1.5 after:-z-10 z-0 hover:after:top-0 hover:after:left-0`}
    >
      {text || 'Shop Now'}
    </Link>
  );
}
