import React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from './images/logo.png';

export default function Logo({ className }) {
  return (
    <Link to="/" className={className}>
      <img src={LogoImage} alt="logo" />
    </Link>
  );
}
