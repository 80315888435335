import React, { useEffect, useState } from 'react';

export default function ErrorPopUp({ message, showPeriod }) {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setIsHidden(false);

    const timeoutId = setTimeout(() => setIsHidden(true), showPeriod || 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [message]);

  if (message === '') return null;

  return (
    <div
      className={`fixed top-1/4 left-1/2 -translate-x-1/2 gap-3 text-lg p-5 z-[99999999999999] items-center bg-white shadow-lg text-red-700 rounded-lg ${
        isHidden ? 'hidden' : 'flex'
      }`}
    >
      <i className="fa-sharp fa-regular fa-circle-xmark" />
      <span>{message}</span>
    </div>
  );
}
