import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import PoweredBy from '@misto/common/components/PoweredBy';
import SocialLinks from '@misto/common/components/SocialLinks';
import ErrorPopUp from '@misto/common/components/ErrorPopUp';
import PopUp from '@misto/common/components/PopUp';
import Throbber from '@misto/common/components/Throbber';
import capitalizeFirstLetter from '@misto/common/helpers/capitalizeFirstLetter';
import shippingImage from '../images/shipping-01.png';
import australiaImage from '../images/australian-01.png';
import safetyImage from '../images/safety-01.png';
import satisfactionImage from '../images/satisfaction-01.png';
import maximizeTawkContext from '@misto/common/context/maximizeTawkContext';

function Footer({ settings }) {
  const maximizeTawk = useContext(maximizeTawkContext);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState({ subscription: false });
  const [errors, setErrors] = useState({ processing: '' });
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      const { data } = await axios.get('/api/categories?showOnFooter=true');

      if (data.categories) setCategories(data.categories);
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => setMessage(''), 5000);

    return () => clearTimeout(timeoutId);
  }, [message]);

  const onSubscribe = async (e) => {
    e.preventDefault();

    try {
      setIsLoading((prev) => ({ ...prev, subscription: true }));
      setErrors((prev) => ({ ...prev, processing: '' }));

      const { data } = await axios.post('/api/subscribers', { email });

      if (data?.email?.msg)
        setErrors((prev) => ({ ...prev, processing: data.email.msg }));
      else
        setMessage(
          'An email has been sent with the verification link of your subscription'
        );
    } finally {
      setIsLoading((prev) => ({ ...prev, subscription: false }));
    }
  };
  return (
    <div className="mt-12 flex flex-col gap-5">
      <div className="bg-black w-screen">
        <div className="w-full max-w-screen-lg m-auto md:px-8 p-3 flex gap-5 items-center text-center text-white">
          <p className="text-xs hidden sm:block">BE IN TOUCH WITH US:</p>
          <form
            onSubmit={onSubscribe}
            className="flex-[2] outline-none flex gap-4"
          >
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="bg-opacity-10 bg-white outline-none py-1 px-2 flex-1"
            />
            <button
              type="submit"
              disabled={isLoading.subscription}
              className="w-20 h-10 flex items-center justify-center border-2 border-white border-opacity-30 md:hover:border-opacity-60 disabled:md:hover:border-opacity-30 transition-all px-2 py-1"
            >
              {isLoading.subscription ? (
                <Throbber className="w-5 h-5 border-2 border-white border-opacity-30" />
              ) : (
                'Join Us'
              )}
            </button>
          </form>
        </div>
      </div>
      <div className="grid gird-cols-1 gap-10 text-center xs:grid-cols-2 xs:text-left px-10 sm:flex max-w-screen-xl mt-4 w-full m-auto">
        <div className="flex flex-1 gap-6 text-sm flex-col">
          <h4 className="font-semibold">Categories</h4>

          {categories.map((c) => (
            <Link
              key={c._id}
              to={`/clothes/${c._id}`}
              className="md:hover:text-black md:transition-all text-gray-600"
            >
              {capitalizeFirstLetter(c.name)}
            </Link>
          ))}
        </div>
        <div className="flex flex-1 gap-6 text-sm flex-col">
          <h4 className="font-semibold">Information</h4>
          <Link
            className="md:hover:text-black md:transition-all text-gray-600"
            to="/site-info/about-us"
          >
            About Us
          </Link>
          <button
            className="md:hover:text-black md:transition-all text-start text-gray-600"
            onClick={maximizeTawk}
          >
            Contact Us
          </button>
          {/* <Link
            className="md:hover:text-black md:transition-all text-gray-600"
            to="/blog"
          >
            Blog
          </Link> */}
          <Link
            className="md:hover:text-black md:transition-all text-gray-600"
            to="/site-info/faqs"
          >
            FAQs
          </Link>
        </div>
        <div className="flex flex-1 gap-6 text-sm flex-col">
          <h4 className="font-semibold">Useful Links</h4>
          <Link
            className="md:hover:text-black md:transition-all text-gray-600"
            to="/site-info/terms-and-conditions"
          >
            Terms & Conditions
          </Link>
          <Link
            className="md:hover:text-black md:transition-all text-gray-600"
            to="/site-info/returns-and-exchanges"
          >
            Returns & Exchanges
          </Link>
          <Link
            className="md:hover:text-black md:transition-all text-gray-600"
            to="/site-info/shipping-and-delivery"
          >
            Shipping & Delivery
          </Link>
          <Link
            className="md:hover:text-black md:transition-all text-gray-600"
            to="/site-info/privacy-policy"
          >
            Privacy Policy
          </Link>
        </div>
        <div className="grid grid-cols-2 gap-5 place-items-center">
          <img src={shippingImage} alt="" />
          <img src={australiaImage} alt="" />
          <img src={satisfactionImage} alt="" />
          <img src={safetyImage} alt="" />
        </div>
      </div>
      <SocialLinks
        className="px-10 w-full max-w-2xl flex gap-5 text-xl"
        linksClassName="hover:opacity-70 transition-all"
      />
      <div className="w-screen bg-gray-100">
        <div className="max-w-screen-4xl m-auto flex px-3 justify-center p-3 items-center text-gray-600">
          <p className="flex-1 text-xs text-gray-600">
            Copyright &#169; 2021 all rights reserved
          </p>
          <PoweredBy className="text-3xl flex items-center gap-5" />{' '}
        </div>
      </div>
      <ErrorPopUp message={errors.processing} showPeriod={5000} />
      {message ? <PopUp message={message} /> : null}
    </div>
  );
}

export default connect(({ settings }) => ({ settings }))(Footer);
