import React from 'react';
import { Outlet } from 'react-router-dom';
import CookieAccess from '../components/CookieAccess';

export default function CookiesPrompt() {
  return (
    <>
      <CookieAccess />
      <Outlet />
    </>
  );
}
