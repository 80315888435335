import React from 'react';

export default function CustomizationInput({ customization, value, setValue }) {
  if (!customization) return null;

  if (customization.type === 'image_upload')
    return (
      <div className="px-5 py-3 flex flex-col gap-3">
        <header className="flex flex-col gap-1">
          <h4>{customization.name}</h4>
          <p className="text-gray-500 text-xs">Image Upload</p>
        </header>{' '}
        <input
          id={customization._id}
          type="file"
          accept="image/*"
          onChange={(e) => setValue(e.target.files[0])}
          hidden
        />
        <label
          className="w-full p-3 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-all"
          htmlFor={customization._id}
        >
          {value ? value.name : 'Upload Image'}
        </label>
      </div>
    );

  return (
    <div className="px-5 py-3 flex flex-col gap-3">
      <header className="flex flex-col gap-1">
        <h4>{customization.name}</h4>
        <p className="text-gray-500 text-xs">Text</p>
      </header>
      <input
        id={customization._id}
        type="text"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        className="px-4 py-2 bg-gray-100 text-gray-700 rounded"
        placeholder="Text"
      />
    </div>
  );
}
