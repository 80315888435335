import React from 'react';
import { Outlet, Navigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';

function RedirectOnAuthOutlet({ auth, allowNonAdmin }) {
  const [searchParams] = useSearchParams();

  if (auth === null) return null;
  if (auth === false) return <Outlet />;
  if (auth.role !== 'admin' && allowNonAdmin) return <Outlet />;

  return <Navigate to={searchParams.get('redirect-url') || '/'} />;
}

export default connect(({ auth }) => ({ auth }))(RedirectOnAuthOutlet);
