import axios from 'axios';
import { FETCH_SETTINGS } from './types';

const fetchSettings = () => async (dispatch) => {
  const { data } = await axios.get('/api/settings');

  dispatch({
    type: FETCH_SETTINGS,
    payload: data,
  });
};

export default fetchSettings;
