import React from 'react';

export default function Paginator({ numberOfPages, setPage, page }) {
  const getOnClick = (pageNo) => () => setPage(pageNo - 1);

  const getPagesNumbersBounds = () => {
    if (page < 3) {
      return [1, Math.min(5, numberOfPages)];
    }
    if (page > numberOfPages - 2) {
      return [Math.max(numberOfPages - 5, 1), numberOfPages];
    }

    return [page - 2, page + 2];
  };

  const renderPagesNumbers = () => {
    const pagesNumbers = [];

    for (
      let i = getPagesNumbersBounds()[0];
      i <= getPagesNumbersBounds()[1];
      i += 1
    ) {
      pagesNumbers.push(
        <button
          className={`${i === page ? 'underline' : ''} text-xl ${
            i !== page
              ? 'md:hover:text-gray-800 md:transition-all text-gray-400'
              : ''
          }`}
          type="button"
          key={i}
          onClick={getOnClick(i)}
        >
          {i}
        </button>
      );
    }

    return pagesNumbers;
  };

  return (
    <div className="flex gap-3 text-lg justify-center mb-4 mt-12">
      <button
        className={`${
          page !== 1 ? 'md:hover:shadow-lg md:transition-all' : ''
        } border-2 border-black w-8 h-8 rounded ${
          page === 1 ? 'text-gray-400 border-gray-400' : ''
        }`}
        disabled={page === 1}
        type="button"
        onClick={() => setPage((prevPage) => prevPage - 1)}
      >
        <i className="fa-solid fa-arrow-left"></i>{' '}
      </button>
      {renderPagesNumbers()}
      <button
        className={`${
          page !== numberOfPages ? 'md:hover:shadow-lg md:transition-all' : ''
        } border-2 border-black w-8 h-8 rounded ${
          page === numberOfPages ? 'text-gray-400 border-gray-400' : ''
        }`}
        disabled={page === numberOfPages}
        type="button"
        onClick={() => setPage((prevPage) => prevPage + 1)}
      >
        <i className="fa-solid fa-arrow-right"></i>{' '}
      </button>
    </div>
  );
}
