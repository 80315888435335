import { ADD_FILTERS, CLEAR_FILTERS, SET_FILTERS } from '../actions/types.js';

// eslint-disable-next-line default-param-last
export default function filtersReducer(state, action) {
  const defaultState = {
    title: '',
    brands: [],
    colors: [],
    sizes: [],
    category: [],
    topPrice: Infinity,
    bottomPrice: 0,
    feed: 'best-sellers',
    page: 0,
    pageSize: 12,
  };

  switch (action.type) {
    case SET_FILTERS:
      return { ...defaultState, ...action.payload };
    case ADD_FILTERS:
      return { ...state, ...action.payload };
    case CLEAR_FILTERS:
      return defaultState;
    default:
      return state || defaultState;
  }
}
