import { combineReducers } from 'redux';
import authReducer from '@misto/common/reducers/authReducer';
import filtersReducer from '@misto/common/reducers/filtersReducer';
import settingsReducer from './settingsReducer';
import cartReducer from './cartReducer';
import isLoginVisibleReducer from './isLoginVisibleReducer';

export default combineReducers({
  auth: authReducer,
  filters: filtersReducer,
  settings: settingsReducer,
  cart: cartReducer,
  isLoginVisible: isLoginVisibleReducer,
});
