import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SignWithGoogleButton from './SignWithGoogleButton.jsx';
import Field from './Field.jsx';
import Throbber from './Throbber.jsx';
import setAuth from '../actions/setAuth.js';
import fetchCart from '../actions/fetchCart.js';
import hideLogin from '../actions/hideLogin.js';

// eslint-disable-next-line no-shadow
function Login({ className, setAuth, fetchCart, hideLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState({
    login: false,
    forgotPassword: false,
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    process: '',
    wait: '',
  });
  const [message, setMessage] = useState('');

  const forgotPassword = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, forgotPassword: true }));

      const { data } = await axios.put('/api/users/password-otp', { email });

      if (data.email) {
        setErrors((prev) => ({ ...prev, process: 'Email does not exist' }));
      } else {
        setMessage('A link has been sent to your email to reset your password');
      }
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        process: err.response?.data.error || err.message,
      }));
    } finally {
      setIsLoading((prev) => ({ ...prev, forgotPassword: false }));
    }
  };

  const onLogin = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, login: true }));

      const { data } = await axios.post('/api/login', {
        email,
        password,
      });

      if (data._id) {
        setAuth(data);
        fetchCart();
        hideLogin();
      } else {
        const updatedErrors = {};
        const errorKeys = Object.keys(errors);

        for (let i = 0; i < errorKeys.length; i += 1) {
          const key = errorKeys[i];

          if (data[key]?.msg) updatedErrors[key] = data[key].msg;
          else updatedErrors[key] = '';
        }

        setErrors(updatedErrors);
      }
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        process: err.response?.data.error || err.message,
      }));
    } finally {
      setIsLoading((prev) => ({ ...prev, login: false }));
    }
  };

  return (
    <div className={className}>
      <p
        className={`${
          message ? 'flex' : 'hidden'
        } gap-3 items-center p-3 rounded bg-green-100 text-green-700`}
      >
        <i className="fa-solid fa-check-circle" />
        <span>{message}</span>
      </p>
      <p
        className={`${
          errors.process ? 'flex' : 'hidden'
        } gap-3 items-center p-3 rounded bg-red-100 text-red-700`}
      >
        <i className="fa-solid fa-xmark" />
        <span>{errors.process}</span>
      </p>
      <p
        className={`${
          errors.wait ? 'flex' : 'hidden'
        } gap-3 items-center p-3 rounded bg-yellow-100 text-yellow-700`}
      >
        <i className="fa-solid fa-clock" />
        <span>{errors.wait}</span>
      </p>
      <Field
        value={email}
        setValue={setEmail}
        error={errors.email}
        label="Email"
      />
      <Field
        value={password}
        setValue={setPassword}
        error={errors.password}
        label={
          <div className="flex items-center justify-between">
            <p>Password</p>
            <button
              type="button"
              className="text-xs text-gray-500 hover:underline font-normal"
              onClick={forgotPassword}
            >
              forgot password
            </button>
          </div>
        }
        type="password"
      />
      <button
        type="button"
        onClick={onLogin}
        disabled={isLoading.login}
        className="flex items-center gap-3 justify-center w-full px-3 py-1.5 text-white text-center bg-[#95b2b8] rounded-full relative before:absolute before:-inset-1 hover:before:border hover:before:border-black before:rounded-full"
      >
        <Throbber
          isHidden={!isLoading.login}
          className="w-4 h-4 border-2 border-white"
        />
        Login
      </button>
      <section className="mt-8 flex gap-5 justify-center">
        <SignWithGoogleButton />
      </section>
      <Link
        to="/register"
        className="text-center w-full px-5 py-2 rounded-full shadow"
      >
        Register
      </Link>{' '}
    </div>
  );
}

export default connect(null, { setAuth, fetchCart, hideLogin })(Login);
