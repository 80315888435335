import React from 'react';
import ReactDOM from 'react-dom';

export default function PopUp({ message, className }) {
  return ReactDOM.createPortal(
    <div className="fixed top-10 z-[99999] left-1/2 -translate-x-1/2 bg-white shadow px-5 py-3 min-w-[150px] min-h-[50px] flex items-center justify-center rounded">
      <span className={className || ''}>{message}</span>
    </div>,
    document.querySelector('#pop-up')
  );
}
