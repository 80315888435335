/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ReactDOM from 'react-dom';

export default function Modal({ isOpen, setIsOpen, children }) {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      onClick={() => setIsOpen(false)}
      className="w-screen h-screen z-[9999999] fixed inset-0 flex items-center justify-center bg-black bg-opacity-30"
    >
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </div>,
    document.querySelector('#modal')
  );
}
