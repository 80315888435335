import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import placeholder from './images/placeholder.jpg';
import Throbber from './Throbber';
import fetchCart from '../actions/fetchCart';
import constants from '../constants';
import AddToCart from './AddToCart';
import PriceLabel from './PriceLabel';

// eslint-disable-next-line no-shadow
function Item({ onClick, auth, fetchCart, item, notModifiable }) {
  const [isLoading, setIsLoading] = useState({ remove: false });
  const [error, setError] = useState();

  const onRemove = async () => {
    try {
      setError('');
      setIsLoading((prev) => ({ ...prev, remove: true }));

      await axios.delete(`/api/items/${item._id}`);
    } catch (err) {
      setError(err.response?.data.error || err.message);
    } finally {
      fetchCart();
      setIsLoading((prev) => ({ ...prev, remove: false }));
    }
  };

  const renderContent = () => {
    const combination = item.product?.combinations.find(
      (c) => c.color.name === item.color && c.size === item.size
    );

    if (!combination)
      return (
        <div className="flex gap-3 items-center">
          <img
            src={placeholder}
            alt="placeholder"
            className="w-1/3 max-w-[150px] aspect-[4/5] bg-gray-50 rounded object-contain"
          />
          <section className="flex flex-col gap-5">
            <h3 className="text-sm text-gray-600">
              This product is no longer available, please remove it from your
              cart.
            </h3>
            <button
              className="flex items-center justify-center text-red-600 bg-red-100 hover:bg-red-200 transition-all h-10 w-10 self-end rounded-full"
              type="button"
              onClick={onRemove}
              disabled={isLoading.remove}
            >
              <Throbber
                isHidden={!isLoading.remove}
                className="w-5 h-5 border-red-600 border-4"
              />
              <i hidden={isLoading.remove} className="fa-solid fa-trash-can" />
            </button>
          </section>
        </div>
      );

    const customization = item.customization
      ? item.product.customizations.find(
          (c) =>
            c.type === item.customization.customizationType &&
            c.name === item.customization.name
        )
      : null;

    let warning =
      !customization && item.customization
        ? 'Customization is unavailable.'
        : '';

    if (combination.qty < item.qty)
      warning = `Item low on stock, only ${combination.qty} is available.`;

    const image = item.product.images.find((img) => img.color === item.color);
    const imageSrc = image
      ? `${constants.apiUrl}/api/products/${item.product._id}/image/${image.color}/${image.name}`
      : placeholder;

    return (
      <>
        <p
          className={`bg-yellow-100 text-yellow-700 text-sm gap-3 px-4 py-2 rounded ${
            warning ? 'flex' : 'hidden'
          }`}
        >
          <i className="fa-solid fa-triangle-exclamation mt-1" />
          {warning}
        </p>
        <div className="flex gap-3 items-center">
          <img
            className="w-1/3 max-w-[150px] aspect-[4/5] object-contain bg-gray-50 rounded"
            src={imageSrc}
            alt={image?.name || 'placeholder'}
          />
          <section className="flex flex-col gap-3">
            <Link
              onClick={onClick}
              to={`/products/${item.product._id}?defaultSize=${item.size}&defaultColor=${item.color}`}
              className="line-clamp-1 font-semibold hover:text-gray-400 transition-all"
            >
              {item.product.title}
            </Link>
            <PriceLabel
              from={auth?.region?.currency || 'aud'}
              price={item.product.price}
            />
            <p className="text-sm text-gray-400">
              {item.color.toUpperCase()}
              <span className="mx-2">|</span>
              {item.size.toUpperCase()}
            </p>
            <div className="flex items-center gap-3">
              {notModifiable ? (
                <p className="text-gray-400">Quantity: {item.qty}</p>
              ) : (
                <AddToCart
                  product={item.product}
                  selectedColor={item.color}
                  selectedSize={item.size}
                />
              )}
              {combination.qty === 0 && !notModifiable ? (
                <button
                  className="flex items-center justify-center text-red-600 bg-red-100 hover:bg-red-200 transition-all h-10 w-10 self-end rounded-full"
                  type="button"
                  onClick={onRemove}
                  disabled={isLoading.remove}
                >
                  <Throbber
                    isHidden={!isLoading.remove}
                    className="w-5 h-5 border-red-600 border-4"
                  />
                  <i
                    hidden={isLoading.remove}
                    className="fa-solid fa-trash-can"
                  />
                </button>
              ) : null}{' '}
            </div>
          </section>
        </div>
        <div
          className={`${
            item.customization ? 'flex' : 'hidden'
          } bg-gray-100 flex-col gap-3 mt-3 rounded-lg p-3`}
        >
          <h4 className="font-semibold">{item.customization?.name}</h4>
          {item.customization?.customizationType === 'image_upload' ? (
            <img
              src={`${constants.apiUrl}/api/items/${item._id}/image`}
              alt=""
              className="m-auto max-w-[150px]"
            />
          ) : (
            <p>{item.customization?.value}</p>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <p
        className={`w-full px-4 py-2 bg-red-100 text-sm text-red-600 rounded gap-3 ${
          error ? 'flex' : 'hidden'
        }`}
      >
        <i className="fa-solid fa-xmark mt-1 font-semibold" />
        {error}
      </p>
      {renderContent()}
    </div>
  );
}

export default connect(({ auth }) => ({ auth }), { fetchCart })(Item);
