import React from 'react';
import HomeHeader from '@misto/common/components/HomeHeader';
import InfoBar from '@misto/common/components/InfoBar';
import { connect } from 'react-redux';

// eslint-disable-next-line no-shadow
function NavBar({ settings }) {
  return (
    <div className="z-20 fixed top-0 w-screen">
      <InfoBar
        address={settings?.address}
        contactNumber={settings?.contact?.phoneNumber}
      />
      <HomeHeader />
    </div>
  );
}

export default connect(({ settings }) => ({ settings }))(NavBar);
