import React from 'react';
import CurrentMenuContext from './currentMenuContext';

export default function Selector({ currentMenu, children }) {
  return (
    <CurrentMenuContext.Provider value={currentMenu}>
      {children}
    </CurrentMenuContext.Provider>
  );
}
