import { FETCH_CART } from '@misto/common/actions/types';

export default function cartReducer(state = null, action) {
  switch (action.type) {
    case FETCH_CART:
      return action.payload;
    default:
      return state;
  }
}
