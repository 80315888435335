import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Item from './Item';
import PriceLabel from './PriceLabel';

function Cart({ auth, cart, settings, setIsCartVisible }) {
  const currency = auth?.region?.currency === 'nzd' ? 'nzd' : 'aud';
  let areAllProductsAvailable = true;

  const renderContent = () => {
    if (!cart || cart.length === 0) {
      return (
        <div className="flex flex-col items-center gap-2 my-10 text-center">
          <i className="fa-solid fa-cart-shopping text-5xl mb-8" />
          <h3 className="text-xl font-semibold">Your Cart is Empty</h3>
          <p className="text-gray-500">
            Add Something to Your Cart to Start Checkout
          </p>
          <Link
            to="/clothes"
            className="px-5 py-1.5 mt-5 text-white font-semibold w-fit bg-[#95b2b8] rounded-full relative before:absolute before:-inset-1 before:rounded-full before:border-2 before:border-transparent hover:before:border-black"
          >
            Start Shopping
          </Link>
        </div>
      );
    }

    return (
      <main className="flex flex-col gap-5">
        {cart?.map((item) => (
          <React.Fragment key={item._id}>
            <Item onClick={() => setIsCartVisible(false)} item={item} />
            <hr />
          </React.Fragment>
        ))}
      </main>
    );
  };

  const getSubtotal = () =>
    cart?.reduce((accumulator, item) => {
      if (!item.product) {
        return accumulator;
      }

      const combination = item.product.combinations.find(
        (c) => c.color.name === item.color && c.size === item.size
      );

      if (!combination) {
        areAllProductsAvailable = false;
        return accumulator;
      }

      let { qty } = item;

      if (combination.qty < item.qty) {
        areAllProductsAvailable = false;
        qty = combination.qty;
      }

      if (!item.customization) {
        return accumulator + qty * item.product.price[currency];
      }

      const customization = item.product.customizations.find(
        (c) =>
          c.name === item.customization.name &&
          c.type === item.customization.customizationType
      );

      if (!customization) {
        areAllProductsAvailable = false;
        return accumulator + qty * item.product.price[currency];
      }

      return (
        accumulator +
        qty * (item.product.price[currency] + customization.price[currency])
      );
    }, 0);

  const getShipping = () => {
    if (settings?.shipping.freeOnOrdersAbove <= getSubtotal()) return 0;

    return settings?.shipping[currency] || 0;
  };

  const renderCheckout = () => {
    if (areAllProductsAvailable)
      return (
        <Link
          onClick={() => setIsCartVisible(false)}
          to="/checkout"
          className="w-full py-2 mt-5 text-center font-semibold rounded bg-black text-white"
        >
          Checkout
        </Link>
      );

    return (
      <div className="mt-5">
        <section className="flex gap-3 items-start text-sm text-gray-500">
          <i className="fa-solid fa-circle-info mt-1" />
          <p>
            Not all products are available, please remove out of stock items to
            start your purchase.
          </p>
        </section>
        <button
          className="w-full py-2 mt-5 text-center font-semibold rounded bg-gray-200 text-gray-600"
          type="button"
          disabled
        >
          Checkout
        </button>
      </div>
    );
  };

  const renderFooter = () => {
    if (!cart || cart.length === 0) return null;

    return (
      <footer className="flex flex-col gap-3">
        <section className="flex justify-between font-semibold text-gray-400">
          <p>Subtotal</p>
          <PriceLabel from={currency} price={{ [currency]: getSubtotal() }} />
        </section>
        <section className="flex font-semibold gap-5 items-center text-gray-400">
          <p className="mr-auto">Shipping</p>
          <p className="text-lg">+</p>
          <PriceLabel from={currency} price={{ [currency]: getShipping() }} />
        </section>
        <hr />
        <section className="flex justify-between font-semibold">
          <p>Total</p>
          <PriceLabel
            from={currency}
            price={{ [currency]: getSubtotal() + getShipping() }}
          />
        </section>
        {renderCheckout()}
      </footer>
    );
  };

  return (
    <div className="flex flex-col gap-5 h-full overflow-auto">
      <header className="flex justify-between font-bold items-center">
        <h1 className="text-2xl">Cart</h1>
        <button
          className="w-8 h-8 text-xl rounded-full hover:bg-gray-100 hover:text-gray-700 transition-all"
          onClick={() => setIsCartVisible(false)}
          type="button"
        >
          <i className="fa-solid fa-xmark" />
        </button>
      </header>
      {renderContent()}
      {renderFooter()}
      {/* <footer className="flex flex-col gap-3">
        <section className="flex justify-between font-semibold text-gray-400">
          <p>Subtotal</p>
          <PriceLabel from={currency} price={{ [currency]: getSubtotal() }} />
        </section>
        <section className="flex font-semibold gap-5 items-center text-gray-400">
          <p className="mr-auto">Shipping</p>
          <p className="text-lg">+</p>
          <PriceLabel from={currency} price={{ [currency]: getShipping() }} />
        </section>
        <hr />
        <section className="flex justify-between font-semibold">
          <p>Total</p>
          <PriceLabel
            from={currency}
            price={{ [currency]: getSubtotal() + getShipping() }}
          />
        </section>
        {renderCheckout()}
      </footer> */}
    </div>
  );
}

export default connect(({ auth, cart, settings }) => ({
  auth,
  cart,
  settings,
}))(Cart);
