import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Convert } from 'easy-currencies';
import getSymbolFromCurrency from 'currency-symbol-map';

function PriceLabel({ price, from, to, className, auth }) {
  const [convertedPrice, setConvertedPrice] = useState('');

  useEffect(() => {
    if (!auth || !price) {
      return () => {};
    }

    const fetchConvertedPrice = async () => {
      const fromCurrency =
        from || (auth.region.currency === 'nzd' ? 'nzd' : 'aud');

      const convertedPriceData = await Convert(price[fromCurrency])
        .from(fromCurrency.toUpperCase())
        .to(to ? to.toUpperCase() : auth.region.currency.toUpperCase());

      setConvertedPrice(Math.round(100 * convertedPriceData) / 100);
    };

    fetchConvertedPrice();

    return () => {}; // cleanup
  }, [auth, price]);

  const renderSymbol = () => {
    if (to) return getSymbolFromCurrency(to);

    return auth ? getSymbolFromCurrency(auth.region.currency) : '$';
  };

  return (
    <p className={className}>
      {renderSymbol()}&nbsp;{convertedPrice || price.aud}
    </p>
  );
}

export default connect(({ auth }) => ({ auth }))(PriceLabel);
