import { SHOW_LOGIN, HIDE_LOGIN } from '@misto/common/actions/types';

export default function isLoginVisibleReducer(state = false, action) {
  switch (action.type) {
    case SHOW_LOGIN:
      return true;
    case HIDE_LOGIN:
      return false;
    default:
      return state;
  }
}
