import React from 'react';
import constants from '../constants.js';

export default function SignWithGoogleButton({ extended }) {
  return (
    <a
      className={`flex ${
        extended
          ? 'w-full justify-center py-1 border items-center gap-5 shadow rounded-full text-gray-500 hover:bg-gray-100 transition-all'
          : ''
      }`}
      href={`${constants.apiUrl}/api/auth/google`}
    >
      <i className="md:hover:text-gray-600 transition-all fa-brands fa-google text-3xl text-gray-500"></i>
      {extended ? <p>Login with Google</p> : null}
    </a>
  );
}
