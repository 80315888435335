import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import fetchCart from '../actions/fetchCart.js';
import CustomizationInput from './CustomizationInput.jsx';
import Menu from './Options/Menu.jsx';
import PriceLabel from './PriceLabel.jsx';
import Selector from './Options/Selector.jsx';
import Throbber from './Throbber.jsx';

// eslint-disable-next-line no-shadow
function AddToCart({ cart, product, selectedColor, selectedSize, fetchCart }) {
  const [quantity, setQuantity] = useState(
    cart?.find(
      (item) => item.color === selectedColor && item.size === selectedSize
    )?.qty || 0
  );
  const [debouncedQuantity, setDebouncedQuantity] = useState(0);
  const [showCustomization, setShowCustomization] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(0);
  const [selectedCustomization, setSelectedCustomization] = useState(null);
  const [value, setValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const combination = useMemo(
    () =>
      product.combinations.find(
        (c) => c.color.name === selectedColor && c.size === selectedSize
      ) || 0,
    [product, selectedColor, selectedSize]
  );

  useEffect(() => {
    setQuantity(
      cart?.find(
        (item) => item.color === selectedColor && item.size === selectedSize
      )?.qty || 0
    );
  }, [selectedColor, selectedSize, cart]);

  useEffect(() => {
    if (selectedCustomization === null) {
      setCurrentMenu(0);
      setValue(null);
    } else setCurrentMenu(1);
  }, [selectedCustomization]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedQuantity(quantity), 500);

    return () => clearTimeout(timeoutId);
  }, [quantity]);

  useEffect(() => {
    const controller = new AbortController();

    const updateQuantity = async () => {
      let data;
      const itemAdded = cart?.find(
        (item) => item.color === selectedColor && item.size === selectedSize
      );

      if (
        debouncedQuantity > 0 &&
        (itemAdded?.qty !== debouncedQuantity || !itemAdded)
      ) {
        const { data: dataRes } = await axios.post(
          '/api/items',
          {
            color: selectedColor,
            size: selectedSize,
            qty: debouncedQuantity,
            productId: product._id,
          },
          {
            signal: controller.signal,
          }
        );

        data = dataRes;
      } else if (itemAdded && quantity === 0) {
        const { data: dataRes } = await axios.delete(
          `/api/items/${itemAdded._id}`,
          {
            signal: controller.signal,
          }
        );

        data = dataRes;
      }

      if (data?._id) fetchCart();
    };

    updateQuantity();

    return () => controller.abort();
  }, [debouncedQuantity]);

  const onCustomize = async () => {
    setIsLoading(true);

    if (selectedCustomization?.type === 'text') {
      const data = {
        productId: product._id,
        color: selectedColor,
        size: selectedSize,
        qty: debouncedQuantity,
        customizationName: selectedCustomization.name,
        customizationType: selectedCustomization.type,
        text: value,
      };

      await axios.post('/api/items', data);
    } else {
      const formData = new FormData();

      formData.append('productId', product._id);
      formData.append('color', selectedColor);
      formData.append('size', selectedSize);
      formData.append('qty', debouncedQuantity);
      formData.append('customizationName', selectedCustomization.name);
      formData.append('customizationType', selectedCustomization.type);
      formData.append('image', value);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      await axios.post('/api/items', formData, { headers });
    }

    fetchCart();
    setIsLoading(false);
  };

  const renderCustomization = () => {
    if (!product || product.customizations.length === 0) return null;

    return (
      <div className="relative">
        <button
          className="px-3 py-2 bg-black text-white text-sm rounded-lg flex items-center gap-3"
          type="button"
          onClick={() => setShowCustomization((prev) => !prev)}
        >
          <i
            className={`fa-solid fa-chevron-down ${
              showCustomization ? 'rotate-180' : 'rotate-0'
            } transition-all`}
          />
          Personalize
        </button>
        <div
          className={`bg-white min-w-[208px] shadow overflow-auto rounded absolute top-[calc(100%+4px)] right-0 transition-all ${
            showCustomization ? 'max-h-60' : 'max-h-0'
          }`}
        >
          <Selector currentMenu={currentMenu}>
            <Menu menuNumber={0}>
              {product.customizations.map((customization) => (
                <button
                  key={customization._id}
                  type="button"
                  className="flex items-center justify-between hover:bg-gray-100 gap-2 p-2.5 w-full"
                  onClick={() => {
                    setSelectedCustomization(customization);

                    if (customization.type === 'text') setValue('');
                  }}
                >
                  <div className="flex flex-col items-start gap-1">
                    <h3 className="text-start">{customization.name}</h3>
                    <p className="text-gray-500">
                      {customization.type === 'text' ? 'Text' : 'Image Upload'}
                    </p>
                  </div>
                  <PriceLabel price={customization.price} />
                </button>
              ))}
            </Menu>
            <Menu menuNumber={1}>
              <button
                type="button"
                className="px-5 py-1 text-gray-500 hover:underline"
                onClick={() => setSelectedCustomization(null)}
              >
                back
              </button>
              <CustomizationInput
                value={value}
                setValue={setValue}
                customization={selectedCustomization}
              />
              <button
                type="button"
                onClick={onCustomize}
                disabled={isLoading}
                className="mx-4 mb-2 z-10 px-4 py-2 flex items-center gap-2 rounded-full text-white relative before:-z-10 before:absolute before:rounded-full before:inset-0.5 before:bg-black border border-transparent hover:border-black disabled:before:bg-gray-100 disabled:text-gray-700"
              >
                <Throbber
                  isHidden={!isLoading}
                  className="w-4 h-4 border-2 border-gray-700"
                />
                Order
              </button>
            </Menu>
          </Selector>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-between items-center w-full gap-1.5">
      <section className="flex items-center justify-between w-full max-w-[100px] gap-1">
        <button
          type="button"
          onClick={() => setQuantity((prev) => prev - 1)}
          disabled={quantity === 0}
          className="w-8 h-8 rounded-full border border-black disabled:border-gray-400 disabled:text-gray-400"
        >
          -
        </button>
        <p>{quantity}</p>
        <button
          type="button"
          disabled={quantity === combination.qty}
          onClick={() => setQuantity((prev) => prev + 1)}
          className="w-8 h-8 rounded-full border border-black disabled:border-gray-400 disabled:text-gray-400"
        >
          +
        </button>
      </section>
      {renderCustomization()}
    </div>
  );
}

export default connect(({ cart }) => ({ cart }), { fetchCart })(AddToCart);
