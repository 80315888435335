import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import showLogin from '../actions/showLogin.js';

// eslint-disable-next-line no-shadow
function PrivateOutlet({ auth, onlyAdmin, showLogin }) {
  const location = useLocation();

  if (!auth) {
    if (onlyAdmin)
      return <Navigate to={`/login?redirect-url=${location.pathname}`} />;

    showLogin();
    return <p className="px-10 py-8 text-2xl">please login to go to page</p>;
  }

  if (auth.role !== 'admin' && onlyAdmin)
    return <Navigate to={`/login?redirect-url=${location.pathname}`} />;

  return <Outlet />;
}

export default connect(({ auth }) => ({ auth }), { showLogin })(PrivateOutlet);
