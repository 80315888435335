import React from 'react';

export default function Field({
  value,
  setValue,
  label,
  extraInfo,
  placeholder,
  type,
  error,
  ...props
}) {
  return (
    <div className="flex flex-1 flex-col gap-1">
      <label className="font-semibold">
        <div>{label}</div>
        {extraInfo ? (
          <span className="text-sm text-gray-400 font-normal">{extraInfo}</span>
        ) : null}
      </label>
      <input
        placeholder={placeholder}
        className="py-2 px-4 bg-gray-100 border-2 border-gray-100 focus:border-[#7d9dff] focus:border-2 outline-none rounded placeholder:text-gray-400"
        // className="px-4 py-2 border-black border rounded-full"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        type={type || 'text'}
        {...props}
      />
      <span className="text-red-700 text-xs">{error}</span>
    </div>
  );
}
